<template>
  <div class="c-app flex-row align-items-center">
  
  </div>
</template>

<script>
export default {
  name: 'Logout',
  data() {
            return {
                input: {
                    email: "",
                    password: ""
                }
            }
        },
      mounted(){
       this.logout()
      },
        methods: {
          async logout() {
            window.localStorage.setItem("user", null);
            window.localStorage.setItem("status", null);
            window.localStorage.setItem("email", null);
            window.localStorage.setItem("iwitness", 'false');
            this.$router.push("/pages/login");
            // location.reload();
      },
         
        }
}
</script>
